<template>
  <div ref="chart" style="width: 100%; height: 100%"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "Chart",
  props: {
    type: {
      type: String,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      chartInstance: null,
    };
  },
  mounted() {
    this.chartInstance = echarts.init(this.$refs.chart);
    this.updateChart();
  },
  watch: {
    series() {
      this.updateChart();
    },
    options() {
      this.updateChart();
    },
  },
  methods: {
    updateChart() {
      const options = {
        ...this.options,
        series: this.series.map((s) => ({ type: this.type, ...s })),
      };
      this.chartInstance.setOption(options);
    },
  },
  beforeDestroy() {
    if (this.chartInstance) {
      this.chartInstance.dispose();
    }
  },
};
</script>