<template>
  <div>
    <CustomGmapAutocomplete
      @gmap-autocomplete::place-changed="handlePlaceChanged"
    ></CustomGmapAutocomplete>

    <div class="w-100 d-flex flex-column mt-4 pt-4">
      <div class="d-flex flex-column">
        <label>Titolo del grafico</label>
        <input type="text" v-model="options.title.text" />
      </div>

      <div class="d-flex flex-column">
        <label>Quante serie</label>
        <input @change="handleChangeQtzSerie" type="number" v-model="nSerie" />
      </div>

      <div v-if="series.length > 0">
        {{ series }}
        <div
          v-for="(serie, index) in series"
          :key="index"
          class="d-flex flex-column"
        >
          <label>Nome serie</label>
          <input type="text" v-model="serie.name" />
        </div>
      </div>
    </div>

    <div v-if="series.length > 0">
      <input type="number" v-model="tempValore" />
      <button
        @click="aggiungiValoreAllaSerie(serie)"
        v-for="(serie, index) in series"
        :key="index"
      >
        aggiungi a {{ serie.name }}
      </button>
    </div>

    <button @click="creaGrafico">Crea grafico</button>

    <div style="height: 700px">
      <EChart
        v-if="echartVisible"
        type="line"
        :series="series"
        :options="options"
      />
    </div>
    <b-button @click="sidebarVisible = !sidebarVisible"
      >Toggle Sidebar</b-button
    >
    <b-sidebar
      id="sidebar-1"
      title="Sidebar"
      lazy
      shadow
      v-model="sidebarVisible"
    >
      <CustomGmapAutocomplete
        @gmap-autocomplete::place-changed="handlePlaceChanged"
      ></CustomGmapAutocomplete>
    </b-sidebar>
  </div>
</template>

<script>
  import EChart from "@/components/EChart.vue";
  import CustomGmapAutocomplete from "@/components/CustomGmapAutocomplete.vue";

  export default {
    name: "TestTry",
    components: {
      EChart,
      CustomGmapAutocomplete,
    },
    data() {
      return {
        sidebarVisible: false,
        nSerie: 1,
        tempValore: 0,
        series: [
          {
            name: "Serie uno",
            data: [],
          },
        ],
        options: {
          title: {
            text: "",
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: this.legendData,
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: ["Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"],
          },
          yAxis: {
            type: "value",
          },
        },
        echartVisible: false,
      };
    },
    methods: {
      handlePlaceChanged(event) {
        console.log(event);
      },
      creaGrafico() {
        this.echartVisible = !this.echartVisible;
      },
      handleChangeQtzSerie() {
        this.echartVisible = false;
        this.series.splice(0);
        for (let i = 0; i < this.nSerie; i++) {
          this.series.push({
            name: "",
            data: [],
          });
        }
      },
      aggiungiValoreAllaSerie(serie) {
        serie.data.push(this.tempValore);
      },
    },
    computed: {
      legendData() {
        return this.series.map((x) => x.name);
      },
    },
  };
</script>
